import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { CoreModule } from 'core';
import {
  ApiResourceService,
  MainUserService,
  RESOURCE_HTTP_INTERCEPTORS,
  ResourceHttpClient,
  UploadInProgressGuard
} from 'data-access';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AppComponent } from './app.component';
import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';
import {
  APP_TYPE_KEY,
  AppTypeEnum,
  DialogModule,
  environment,
  IMAGE_INPUT_SERVICE_KEY,
  IMAGE_VIEWER_SERVICE_KEY,
  MANAGEMENT_TOKEN_SERVICE,
  ORGANIZATION_TOKEN_SERVICE,
  RESOURCE_KEY,
  USER_KEY,
  FileDownloadService,
  CONFIRM_SERVICE_KEY,
  ConfirmService
} from 'shared';

import { AppRoutingModule } from './app-routing.module';
import { WebLayoutModule } from './modules/layout/web-layout.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WebManagementTokenService } from '@web/app/services/web-management-token.service';
import { WebOrganizationTokenService } from '@web/app/services/web-organization-token.service';
import { WebManagementInterceptor } from '@web/app/interceptors/web-management-interceptor.service';
import { WebOrganizationInterceptor } from '@web/app/interceptors/web-organization.interceptor';
import {
  MANAGEMENT_HTTP_INTERCEPTORS,
  ManagementHttpClient
} from '../../../../libs/data-access/src/lib/interceptors/manangement-server-client.service';
import { WebImageInputService } from '@web/app/services/web-image-input.service';
import { WebImageViewerService } from '@web/app/services/web-image-viewer.service';
import { SpinnerModule } from 'primeng/spinner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { WebFileDownloadService } from '@web/app/services/web-file-download.service';
import { AppMessageModule } from 'shared';
import { WebDialogModule } from '@web/app/modules/dialog/web-dialog.module';
import { WebConfirmService } from '@web/app/services/web-confirm.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    DialogModule,
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    SharedPageModule,
    AuthModule.forRoot(environment.auth),
    WebLayoutModule,
    HttpClientModule,
    AppMessageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,

        deps: [HttpClient]
      },
      defaultLanguage: 'de'
    }),
    SpinnerModule,
    ProgressSpinnerModule,
    WebDialogModule
  ],
  providers: [
    UploadInProgressGuard,
    NgxImageCompressService,
    ResourceHttpClient,
    ManagementHttpClient,
    DatePipe,
    {
      provide: MANAGEMENT_HTTP_INTERCEPTORS,
      useClass: WebManagementInterceptor,
      multi: true
    },
    {
      provide: RESOURCE_HTTP_INTERCEPTORS,
      useClass: WebOrganizationInterceptor,
      multi: true
    },
    {
      provide: MANAGEMENT_TOKEN_SERVICE,
      useClass: WebManagementTokenService,
      deps: [Store, AuthService]
    },
    {
      provide: ORGANIZATION_TOKEN_SERVICE,
      useClass: WebOrganizationTokenService,
      deps: [Store, AuthService]
    },
    {
      provide: RESOURCE_KEY,
      useClass: ApiResourceService,
      deps: [ManagementHttpClient, ResourceHttpClient, DatePipe]
    },
    {
      provide: USER_KEY,
      useClass: MainUserService,
      deps: [AuthService]
    },
    {
      provide: APP_TYPE_KEY,
      useValue: AppTypeEnum.WEB
    },
    {
      provide: IMAGE_INPUT_SERVICE_KEY,
      useClass: WebImageInputService
    },
    {
      provide: IMAGE_VIEWER_SERVICE_KEY,
      useClass: WebImageViewerService
    },
    {
      provide: FileDownloadService,
      useClass: WebFileDownloadService
    },
    {
      provide: ConfirmService,
      useClass: WebConfirmService
    }
  ],
  bootstrap: [AppComponent],
  exports: [CommonModule]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
